//COLORS VARIABLES ==================================
$red: #b20038;
$white: white;
$white_70: rgba(255, 255, 255, 0.7);
$color_log_cabin_approx: #222222;
$black_55: rgba(0, 0, 0, 0.55);
$color_shiraz_75_approx: rgba(178, 0, 56, 0.75);
$black_30: rgba(0, 0, 0, 0.3);

// MEDIA QUERIES ---------------------------------------------------

$mobile:"(max-width: 601px)";
$tablet:"(min-width: 601px) and (max-width: 991px)";
$tabletSmall:"(min-width: 600px) and (max-width: 670px)";
$desktopSmall:"(min-width: 991px) and (max-width: 1199px)";
$desktop:"(min-width: 991px)";
$desktopLarge:"(min-width: 1600px)";
$xl:"(min-resolution: 2dppx) and (min-width: 1300px)"; //4k

.flex {
  display: flex;
}

// ADDCLASS
.container-fluid {
  padding: 0;
}
.container, .container-fluid{
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 1200px) {
    &.wide {
      width: 100%;
      max-width: 1600px;
      @media #{$mobile}{
        padding: 0 15px;
      }
    }
    &.large{
      width: 100%;
      max-width: 1440px;
      @media #{$mobile}{
        padding: 0 15px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    &.wide {
      width: 100%;
      max-width: 900px;
      @media #{$mobile}{
        padding: 0 15px;
      }
    }
    &.large{
      width: 100%;
      max-width: 900px;
    }
  }

  > .row {
    margin: 0;
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $red;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

// LOGO ==================================
#sidebar-toggle {
  height: auto;
  width: auto;
  max-width: 50px;
  z-index: 999;
  padding: 5px;

  .picto-icon {
    display: block;
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    width: auto;

    img {
      margin: 0 !important;
      max-width: 100%;
    }
  }
}


// FILTER NAV + BANNER TOP ==================================
.landing, .landing-form {
  .filter-nav {
    .wfilter-trigger {
      z-index: 99999;
      background: $white;
      height: 100vh;
      width: 70px;

      @media screen and(max-width: 800px), screen and (max-height: 600px) {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }

      a {
        text-transform: uppercase;
        color: $red;
        font-size: 1.3em;
        position: absolute;
        top: 350px;
        left: 50%;
        display: block;
        transform-origin: top left;
        transform: rotate(-90deg) translateY(-50%);
        margin: 0;
        white-space: nowrap;
        font-weight: 400;
        padding: 26px 0 26px 50px;

        @media screen and(max-width: 800px), screen and (max-height: 600px) {
          display: none;
        }
        &:before {
          content: '';
          height: 2px;
          width: 35px;
          background: $red;
          position: absolute;
          left: 0;
          top: 50%;
        }
      }
      .cta-contact {
        top: calc(100% - 95px);
        padding: 26px 0;
        font-size: .98em !important;
        font-weight: 400;
        color: $red !important;
        backface-visibility: hidden;

        @media screen and(max-height: 650px) {
          font-size: .8em !important;
          top: calc(100% - 80px);
        }

        &:before {
          display: none;
        }
      }
      .cta-categories-icons {
        transition: all .3s ease;
        display: none;
        position: relative;
        width: 25px;
        height: 2px;
        background: $red;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and(max-width: 800px), screen and (max-height: 600px) {
          display: block;
        }
        &:after{
          content: '';
          transition: all .3s ease;
          position: absolute;
          top: -10px;
          width: 25px;
          height: 2px;
          background: $red;

        }
        &:before {
          content: '';
          transition: all .3s ease;
          position: absolute;
          bottom: -10px;
          width: 25px;
          height: 2px;
          background: $red;
        }
      }
    }
    .toggle-wfilter {
      transition: all .4s ease;
      width: 250px;
      background: $red;
      transform: translateX(100%);
      right: 70px;

      @media screen and(max-width: 800px), screen and (max-height: 600px) {
        width: 100%;
        padding: 5em 12px 4em 12px;
        right: 0;
        overflow-y: auto;
      }

      > h4 {
        display: none;
        position: relative;
        color: white;
        padding: 0 20px 20px 20px;
        text-transform: uppercase;
        margin-bottom: 20px;

        &:after{
          content: '';
          position: absolute;
          width: 35px;
          bottom: 0;
          height: 2px;
          left:20px;
          background: white;
        }
        @media screen and(max-width: 800px), screen and (max-height: 600px) {
          display: block;
        }
      }

      > ul {
        top: 0;
        max-height: 100vh;
        overflow-y: auto;
        padding: 20px 12px;

        @media screen and(max-width: 800px), screen and (max-height: 600px) {
          padding: 0;
          position: relative;
          overflow-y: hidden;
        }
      }
    }
    .iso-filter-nav {
      background: transparent !important;
    }
    .content-toggle > ul li {
      text-align: left;
      a {
        color: rgba(255,255,255,.7);
        font-size: .9em;
        &:hover {
          color: white !important;
        }
      }
    }
    .parents-toggle > a {
      text-align: left;
    }
    &.opened {
      .wfilter-trigger{
        .cta-categories-icons {
          background: rgba(178, 0, 56, 0);
          &:after {
            top:0;
            transform: rotate(45deg);
          }
          &:before {
            bottom:0;
            transform: rotate(-45deg);
          }
        }
      }
      .toggle-wfilter {
        transform: translateX(0);
      }
    }
  }

  // BANNER TOP
  .banner, .intro {
    min-height: 350px;
    height: 48vh;

    @media screen and(max-width: 900px) {
      max-height: 400px;
    }
  }

  .parallax header h1 {
    padding: 1em 1em;
    position: absolute;
    bottom: 0;
    text-align: left;
    width: 100%;
    color: $white !important;
    transform: translateY(40px);
    animation: fadinUp .8s ease 1s both running;
    backface-visibility: hidden;

    span {
      color: $white !important;

      @media screen and(max-width: 600px) {
        font-size: 42%;
      }
    }
  }

  #wrapper {
    padding-right: 70px;
    @media screen and (max-width: 800px), screen and (max-height: 600px) {
      padding-right: 0;
    }
  }

  #section2 {
    .innerContent {
      img {
        width: 100%;
        object-fit: cover;
        height: 350px;
      }
    }
  }
}
@keyframes fadinUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.parallax {
  > header {
    height: 100%;
    position: relative;
    width: 100%;
    .parallax-background {
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// END (FILTER NAV + BANNER TOP) ===

.landing {
  .grid-container{
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: "block--one   block--two"
                         "block--three block--four";

    @media screen and(max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas: "block--one"
                           "block--three"
                           "block--two"
                           "block--four";
    }

    .grid-block--one{
      grid-area: block--one;
      position: relative;
    }
    .grid-block--two{
      grid-area: block--two;
    }
    .grid-block--three{
      grid-area: block--three;
      article {
        height: 100%;
        @media screen and(max-width: 1024px) {
          width: 100%;
        }
      }
    }
    .grid-block--four{
      grid-area: block--four;
    }
  }
  #section1 {
    .innerContent {
      right: 0;
      bottom: 0;
      display: block;
    }
    #left-content-description {

    }
    .work__container.large{
      article {
        &.txt-item-inner {
          border-width: 0 0 0 0;
        }
      }
    }
    article {
      //overflow-x: hidden;
      &.txt-item-inner {
        display: flex;
        overflow-y: auto;
        z-index: 0;
        border-style: solid;
        border-color: white;
        border-width: 1px 1px 0 0;
        padding: 0!important;

        @media screen and(max-width: 1024px) {
          border-width: 0;
        }

        @media screen and(max-width: 600px) {
          display: none;
        }

        > div {
          margin: auto 0;
          padding: 1em 0em !important;
          width: 100%;
          > p {
            padding: 0 1.5em 0.3em 1.5em;
            color: $white;
            text-align: left;
            margin-bottom: 25px;
          }
          > ul {
            margin-bottom: 0;
          }
        }
        @media screen and(max-width: 1280px) {
          width: 100%;
          position: relative;
          border-width: 1px 0px 0px 0px;
        }
      }
    }
    .info {
      article{
        position: absolute;
        @media screen and(max-width: 1024px) {
          position: relative;
        }
        margin: auto;
        display: flex;
        flex-flow: column;
        height: 100%;
        border-top: 1px solid rgb(255, 255, 255);
        //overflow-y: auto;
        width: 100%;

        .description--container {
          transition: .4s height ease;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          flex: 0 1 auto;

          @media screen and(max-width: 1300px) {
            p{
              font-size: 0.9em;
            }
          }
        }

        h2 {
          color: $red;
          font-size: 2em;
          font-weight: 300;
        }
        a {
          transition: .3s color ease;
          position: relative;
          display: inline-block;
          text-transform: uppercase;
          color: $red;
          padding: 10px 5px;
          box-sizing: border-box;
          overflow: hidden;
          letter-spacing: 2px;
          font-size: 1.05em;
          z-index: 0;

          min-height: 38px;
          margin: 1em auto 4em 0;

          @media screen and(max-width: 1280px) {
            margin: 1em auto;
          }

          &:before {
            content: '';
            transition: .3s width ease;
            height: 2px;
            width: 30%;
            position: absolute;
            left: 0;
            background: #b20038;
            bottom: 0;
          }

          &:hover{

            &:before {
              width: 100%;
              z-index: -1;
            }
          }

          @media screen and(max-width: 1500px) {
            //margin: 20px auto auto 0;
          }

          @media screen and(max-width: 1280px) {
            letter-spacing: 1px;
            font-size: 0.9em;
          }
        }
      }
    }
    .full-h.inner-pad {
      padding: 2em 4em !important;
    }
  }
  #section2 {
    @media screen and(max-width: 600px) {
      display: none;
    }
    .row .b-3 {
      width: 33.3333% !important;
      padding-bottom: 0 !important;
      border-style: solid;
      border-color: white;
      border-width: 1px 1px 0px 0px;

      &:nth-of-type(3n) {
        border-right: 0px;
      }

      .innerContent {
        position: relative;
        top:0;
        left:0;
        bottom:0;
        right: 0;

        img {
          width: 100%;
          object-fit: cover;
          height: 350px;

          @media screen and(max-width: 1280px) {
            height: 250px;
          }
        }
      }
    }
  }
  .row-expertises {
    @media screen and(max-width: 1024px) {
      width: 100%;
    }

    [class^="b-"] {
      @media screen and(max-width: 1024px) {
        width: 100%;
        padding: 0;
      }

      article {
        @media screen and(max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }
  .expertises--container{
    flex: 1 1 auto;
    width: 100%;
    z-index: 2;
    position: relative;
    top: 0;
    border-right: 1px solid white;
    border-top: 1px solid white;
    height: 460px;

    @media screen and(max-width: 1280px) {
      height: 300px;
    }
    @media screen and(max-width: 1024px) {
      border-right: none;
    }

    .bg-content {
      padding: 3em;
      display: flex;
      flex-direction: column;
      height: 100%;

      @media screen and(max-width: 1500px) {
        padding: 2em 3em;
      }
      @media screen and(max-width: 1280px) {
        padding: 1.5em 2.5em;
      }
      @media screen and(max-width: 1024px) {
        padding: 1.5em 4em;
      }
      @media screen and(max-width: 600px) {
        padding: 1em 2em;
      }

      > * {
        margin: auto auto auto 0;
        padding: 10px 0;

        @media screen and(max-width: 1500px) {
          padding: 10px 0 10px;
        }
        @media screen and(max-width: 1280px) {
          padding: 0;
        }
      }

      h3 {
        color: $red;
        text-transform: uppercase;
        font-size: 2.3rem;
        font-weight: 300;
        padding: 5px 0 15px;

        @media screen and(max-width: 1500px) {
          font-size: 2.2rem;
          text-shadow: 0 0 4px rgba(55, 55, 55, 0.2);
        }
        @media screen and(max-width: 1280px) {
          font-size: 1.5rem;
          padding: 5px 0;
        }
        @media screen and(max-width: 800px) {
          font-size: 1.7rem;
        }
        @media screen and(max-width: 600px) {
          font-size: 1.5rem;
        }
      }

      .expertises--items {
        display: flex;
        flex-direction: column;
        padding-top: 2em;

        @media screen and(max-width: 1500px) {
          width: 100%;
        }
        @media screen and(max-width: 1280px) {
          padding-top: 1.5em;
        }
        @media screen and(max-width: 800px) {
          padding-top: 1em;
        }
      }

      .expertises--item {
        display: flex;

        h4 {
          color: white;
          font-size: 1.2em;

          @media screen and(max-width: 1500px) {
            font-size: 1em;
            text-shadow: 0 0 4px rgba(55, 55, 55, 0.2);
          }
          @media screen and(max-width: 1280px) {
            font-size: .9em;
          }
          @media screen and(max-width: 800px) {
            font-size: 1em;
          }
          @media screen and(max-width: 600px) {
            font-size: 0.9em;
          }
        }
        .ico {
          margin: auto 35px auto 0;
          width: 35px;
          height: auto;

          @media screen and(max-width: 1500px) {
            width: 25px;
          }
        }
      }
    }

    .bg-image--container {
      overflow: hidden;
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .bg-image{
        transform: scale(1.2);
        filter: blur(10px) brightness(65%);
        background-size: cover;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
      }
    }
  }
  .messagerie {
    background: $red;
    .cta-contact {
      text-transform: uppercase;
      color: $red;
      font-size: 1.3em;
      position: absolute;
      top: 225px;
      left: 50%;
      display: block;
      transform-origin: top left;
      transform: rotate(-90deg) translateY(-50%);
      margin: 0;
      white-space: nowrap;
      &:before {
        content: '';
        height: 2px;
        width: 35px;
        background: $red;
        position: absolute;
        left: -50px;
        top: 10px;
      }
    }
  }
  .tabs {
    overflow: hidden;
    [class^="b-"] {
      position: relative;
      float: left;
      padding-bottom: inherit;
      display: flex;
    }
    .innerContent {
      position: relative;
      padding: 0 !important;
    }
  }
  .cta-box {
    position: fixed;
    right: 90px;
    width: 35vw;
    min-width: 400px;
    max-width: 580px;
    height: auto;
    background-color: $red;
    //background-color: #bd003c;
    transition: all 0.6s ease;
    bottom: -300px;
    opacity: 0;
    z-index: 3;
    box-shadow: rgba(0,0,0, .2) 0 0 30px 0;

    @media screen and(max-width: 800px) {
      min-width: auto;
    }

    a {
      display: block;
      position: relative;
      padding: 1.5em 2em;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 0;
      background: transparent;
    }
    h3 {
      text-transform: uppercase;
      font-size: 2.2em;
      color: $white;
      display: table;
    }
    h4 {
      position: relative;
      color: white;
      font-size: 1.2em;
      text-transform: inherit;
      display: table;
    }
    .cta-close {
      transition: all .2s ease;
      height: 20px;
      width: 20px;
      display: block;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;

      &:hover {
        opacity: .6;
        -webkit-transform:  rotate(-90deg) ;
        transform:  rotate(-90deg) ;
      }

      &:before {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 0;
        left: 50%;
        height: 1.5em;
        width: .15em;
        background: white;
        content: '';
        display: block;
        margin: -3px 0 0 -1px;
        transform: rotate(-45deg);
      }

      &:after {
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 0;
        left: 50%;
        transform: rotate(45deg);
        height: 1.5em;
        width: .15em;
        background: white;
        content: '';
        display: block;
        margin: -3px 0 0 -1px;
      }
    }
    &.cta-in {
      bottom: 0;
      opacity: 1;
    }
    &.closed {
      bottom: -300px;
      opacity: 0;
    }
  }
  .services.solutions {
    padding: 7.5em 2.5em;
    .inner-pad {
      padding: 0.5em 2em !important;
    }
    .fa, .ico {
      margin-left: inherit;
      padding: 10px 10px 5px 0;
      position: relative;
      top: inherit;
      font-size: 2em;
      display: block;
      text-align: left;
    }
    h3 {
      text-align: left;
      padding: 0;
      font-size: 1.2em;
      text-transform: uppercase;
    }
    p {
      //text-align: left;
      padding: 0;
      text-align: justify;
      > span {
        padding: 0;
      }
    }
    .row {
      position: relative;
      box-sizing: border-box;
      overflow: hidden;

    }
    .row-container {
      position: relative;
      height: auto;
      overflow: hidden;
    }
    [class^="b-"] {
      position: relative;
      float: left;
      padding-bottom: inherit;
      display: flex;
    }
    .innerContent {
      left: inherit;
      right: inherit;
      top: inherit;
      bottom: inherit;
      position: relative;
      padding: 1.5em 2em;
    }
  }
  .video {
    right: 0;
    bottom: 0;
    cursor: pointer;

    border-style: solid;
    border-color: white;
    border-width: 1px 0px 0px 0px;

    @media screen and(max-width: 600px) {
      display: none;
    }

    .snapshot-container {
      transition: all .6s ease;
      opacity: 1;
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;

      &:before {
        content: '';
        transition: all .4s ease;
        position: absolute;
        background: rgba(0, 0, 0, 0.55);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .img-work-video {
        @extend .img-cover;
      }
      img.icon-player {
        transition: all .4s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        z-index: 2;
        @media screen and(max-width: 1500px) {
          width: 65px;
        }
      }
    }
    .video-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      iframe {
        width: calc(100% + 1px);
        height: 100%;
        @media screen and(max-width: 600px) {
          width: 100%;
        }
      }
    }
    .inner-content {
      padding-top: 56.2%;
      height: 0;
      overflow: hidden;
    }
    .icon-player {
      transition: all .4s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 85px;
      @media screen and(max-width: 600px) {
        width: 70px;
      }
      @media screen and(max-width: 400px) {
        width: 60px;
      }
      @media screen and(max-width: 300px) {
        width: 50px;
      }
    }
    &:hover {
      .snapshot-container {
        &:before {
          background: rgba(0, 0, 0, 0.3);
        }
        .icon-player {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }
    }
    &.show {
      .snapshot-container {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .tabs-nav {
    overflow: hidden;

    .list-content {
      padding: 0 !important;
      height: 230px;
      overflow: hidden;
      border-style: solid;
      border-color: white;
      border-width: 1px 1px 0 0;

      @media screen and (min-width: 1024px) and (max-width: 1280px){
        height: 150px;
      }
      @media screen and(max-width: 600px) {
        border-width: 1px 0 0 0;
      }

      &:nth-of-type(3n){
        border-width: 1px 0 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    [class^="b-"] {
      position: relative;
      float: left;
      padding-bottom: inherit;
      display: block;
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        &:before {
          content: '';
          transition: all .4s ease;
          position: absolute;
          background: $black_55;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        &:after {
          transition: all .4s ease;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          outline: 0 solid $color_shiraz_75_approx;
          outline-offset: 0;
          opacity: 0;
          z-index: 2;
        }
        span {
          transition: all .4s ease;
          position: absolute;
          top: 50%;
          left: calc(50% - .5px);
          transform: translate(-50%, -50%);
          width: 90%;
          color: $white;
          text-transform: uppercase;
          line-height: 1.25em;
          font-weight: 300;
          font-size: 1.25em;
          text-align: center;
          z-index: 2;

          @media screen and(max-width: 1280px) {
            line-height: 1.25em;
            font-size: 1.1em;
          }
          @media screen and(max-width: 1024px) {
            line-height: 1.2em;
            font-size: 1em;
          }
        }
        &:hover {
          &:before {
            background: $black_30;
          }
          &:after {
            outline: 15px solid $color_shiraz_75_approx;
            outline-offset: -15px;
            opacity: 1;

            @media screen and(max-width: 1024px) {
              outline: 8px solid $color_shiraz_75_approx;
              outline-offset: -8px;
            }
          }
        }
      }
    }
  }
}

[class^="b-"] {
  padding-bottom: inherit;
}


@media screen and(max-width: 800px) {
  .landing {
    p {
      font-size: 0.85em;
    }
    #section1 {
      .innerContent {
        position: relative !important;
        display: block;
        .valign {
          top: 0;
          transform: translateY(0);
        }
      }
      .info, .right {
        padding-bottom: inherit;
      }
      .info article {
        h2 {
          font-size: 1.8em;
          text-align: left;
        }
        //p {
          //text-align: left;
        //}
      }
      .row-description .txt-item-inner ul li {
        text-align: left;
      }
      .full-h.inner-pad {
        padding: 2em !important;
      }
      .row-description .b-2 article {
        text-align: left;
        ul li {
          text-align: left;
        }
      }
      article.txt-item-inner > div {
        > p {
          padding: 0 1em 0.3em 1em;
        }
        > ul li {
          padding: 0.3em 1em;
        }
      }
    }
    .cta-box {
      width: 100%;
      right: 0;
      //min-height: 180px;
      font-size: 0.85em;
      max-width: 440px;
    }
    .b-2 {
      padding-bottom: 0;
    }
    .services.solutions {
      padding: 5.5em 0;
      .b-3 {
        width: 100%;
      }
      .b-6 {
        width: 100%;
      }

    }
    #section2 .row.ht1.m-full [class^="b-"] {
      padding-bottom: 50%;
      width: 100%;
    }
  }
}
@media screen and(min-width: 800px) and(max-width: 1190px) {
  .landing #section1 .innerContent .white-bg .valign {
    top: 0;
    transform: translateY(0);
  }
}
@media screen and(max-width: 800px) and(max-width: 1250px) {
  .landing #section1 article.inner-pad {
    padding: 2em !important;
  }
}
@media screen and(max-width: 1190px) {
  .landing #section2 .row.ht1.m-full [class^="b-"] {
    padding-bottom: 30%;
    width: 50%;
  }
}
@media screen and(max-width: 1024px) {
  .landing {
    #section1 {
      .info, .right {
        width: 100%;
      }
    }
    .parallax {
      height: 45vh;
      min-height: 350px;
      header h1 {
        font-size: 3.8em;
        padding: 0 .75em .6em .75em;
        margin: 0;
      }
    }
    .tabs {
      .b-6 {
        width: 66.6666%;
      }
      > {
        .b-3 {
          width: 100%;
        }
        .b-6 {
          width: 100%;
        }
      }
    }
    .services.solutions .b-6 {
      width: 66.6666%;
      .b-3 {
        width: 100%;
      }
    }
  }
}
@media screen and(max-width: 800px) {
  .landing {
    .parallax {
      header h1 {
        font-size: 3.5em;
        padding: 0 .75em .6em .75em;
      }
    }
  }
}

@media screen and(max-width: 1280px) {
  .landing .cta-box {
    font-size: .9em;
    width: 100%;
    max-width: 500px;
  }
  .b-7{
    width: 100%;
  }
}
@media screen and(max-width: 600px) {
  .landing {
    .parallax {
      header h1 {
        font-size: 2.2em;
        padding: 0 1em .6em 1em;
      }
    }
    .cta-box {
      max-width: 100%;
      width: 100%;
      a {
        padding: 1em 2em;
      }
      h3 {
        font-size: 2em;
      }
    }
  }
}

.landing{
  .row-description{
    .full-h{
      position: absolute;
    }
  }
  .inner-pad{
    padding:  2em 2.5em !important;
  }
  .b-3.info {
    .inner-pad{
      padding:  2em 2.5em !important;
    }
    article{
      height: auto;
      //overflow-y: auto;
    }
  }
}

.work__container {

  .work__container--description {
    opacity: 1;
    transition: .4s all ease;

    @media screen and(max-width: 1280px) {
      max-height: 100%;
    }
  }

  .work__container--video {
    max-width: 75%;
    transition: .4s all ease;

    @media screen and(max-width: 1280px) {
      max-width: 100%;
    }
  }

  &.large {

    .work__container--description {
      opacity: 0;

      @media screen and(max-width: 1280px) {
        opacity: 1;
        max-height: 0;
      }
    }

    .work__container--video {
      max-width: 100%;
    }
  }
}

.landing .tabs li{
  overflow: hidden;
}

.landing .tabs img{
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition:  all 0.3s ease;
}

.landing .tabs a:hover img{
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
}
.landing .tabs a:hover h3{
  letter-spacing: 1px;
}

/* VIDEO MODAL - LIGHTBOX  ------------------------- */

.videobox{
  position: relative;
  overflow: hidden;
}
.videobox:before{
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: cyan;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition:  all 0.3s ease;
  /*opacity: 0;
  visibility: hidden;*/
}

.vbox-content iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vbox-content, .venoframe{
  width: 100%;
  position: relative;
  padding: 0;
  border: none;
  background: transparent !important;
  //padding-top: 56.25%;
  //max-width: calc(177.778vh) !important;
  //height: calc(56.25vw) !important;
  /*max-width: 960px !important;
  max-height: 547px !important;*/


  height: auto;
  width: 100% !important;
  margin: 0;
}

.vbox-overlay{
  z-index: 99999;
  cursor: pointer;
  .vbox-container {
    overflow-y: hidden;
    .vbox-content {
      overflow-y: auto !important;
      max-height: 100vh;
    }
  }
}

.vbox-close {
  top: 0;
  width: 50px;
  height: 50px;
  font-size: 40px;
  color: $red !important;
  background-color: white !important;
  padding: 4px !important;
}
.vbox-content {
  display: flex;
  margin: 0 !important;
  height: 100%;

  @media screen and(max-width: 600px) {
    padding: 0 !important;
  }
  .venoframe {
    @media screen and(max-width: 600px) {
      padding: 15px !important;
    }
  }

  .figlio {
    box-shadow: none !important;
    width: 960px !important;

    @media screen and (min-width: 768px) {
      width: 640px !important;
    }
    @media screen and (min-width: 992px) {
      width: 960px !important;
    }
  }

  .vbox-inline {
    height: auto;
    display: block;
    margin: auto;

    @media screen and(max-width: 600px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      overflow-y: auto;
      max-height: 100vh;
      max-width: 100%;
      width: 100%;
      cursor: default;
    }

    > article {
      @media screen and(max-width: 600px) {
        grid-column: 1;
        grid-row: 2;
      }
      .inner {
        padding: 20px;
        text-align: left;
        li {
          margin-bottom: 10px;
          b {
            display: block;
            font-weight: 500;
            color: $red;
          }
        }
      }
    }
    > .video {
      @media screen and(max-width: 600px) {
        grid-column: 1;
        grid-row: 1;
      }
    }


    .video-content {
      @media screen and(max-width: 600px) {
        padding-top: 56.25%;
      }
      iframe {
        @media screen and(max-width: 600px) {
          position: absolute;
        }
      }
    }
  }

  iframe {
    position: relative;
    margin: auto;
  }

  .snapshot-container {
    display: none;
  }
  .txt-item-inner {
    height: auto !important;
  }
}

.landing .txt-inner{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  padding: 1em;
  z-index: 9;
  background-color: rgba(0,0,0, .2);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition:  all 0.3s ease;
}

.landing .txt-inner *{
  display: table-cell;
  vertical-align: middle;
}
.landing .txt-inner h3{
  font-size: 1.1em;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition:  all 0.3s ease;
}

.img-cover {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: relative;
  min-height: 100%;
  min-width: 100%;
  display: block;
  max-width: inherit;
  overflow: hidden;
  left: 50%;
  top: 50%;
  max-height: 110%;
}


/* SERVICES SECTIONS override
------------------------------------------ */
.landing .ico{
  display: block;
  width: 44px;
  height: 50px;
}
.services .innerContent .ico img{
  display: inline-block;
  position: inherit;
  top:inherit;
}
.landing .services.solutions h3{
  font-size: 1.1em;
  padding: 1em 0 1em;
  margin: 0;
}

.landing .services.solutions .b-6{
  width: 50%;
  display: block;
  float: left;
  min-height: 120px;
  padding: 0 0.5em 0 0;

  @media screen and (min-width: 1025px) and (max-width: 1280px){
    width: 100%;
    float: none;
    min-height: auto;
    margin-bottom: 30px;
  }

  @media screen and(max-width: 400px) {
    width: 100%;
    float: none;
    min-height: auto;
    margin-bottom: 30px;
  }
}
.landing .services.solutions .b-6 h4{
  text-align: left;
  font-size: 0.95em;
  text-transform: uppercase;
}
@media screen and (max-width: 800px){
  .landing .services.solutions .b-6 h4{
    font-size: 0.85em;
    word-wrap: break-word;
  }
}

// BASIC PAGE
.basic-page {
  p a {
    color: $red;
  }
  article {
    width: 100%;

    &.inner-pad {
      padding: 2.5em !important;
    }
    &.inner-pad {
      @media screen and (max-width: 600px){
        padding: 2em 1.9em !important;
      }
    }
    h2, h3, h4 {
      text-transform: inherit;
      letter-spacing: inherit;
      font-style: italic;
      margin-bottom: 15px;
      color: #141414;
      opacity:.85;
    }

    h3 {
      font-size: 0.95em;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: inherit;
    }
    h3,h2 {
      +h4 {
        margin-top: 15px;
      }
    }
    h4 {
      font-size: 0.95em;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: inherit;
    }
    p {
      padding: 0 !important;
      margin-bottom: 25px;
    }
    ul, ol {
      padding: 0 2% !important;
      margin-bottom: 25px;
    }
    p, ul, ol{
      +h4, +h3,+h2{
        margin-top: 50px;
      }
    }
  }
}
.parallax-background {
  img{
    text-align: center;
  }
}

/*
.landing .video-overlay{
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
}*/

/* --- SCROLLBAR - STYLE --- */
::-webkit-scrollbar-track {
  box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:0;
  background-color: $red;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: $red;
}

::-webkit-scrollbar-thumb {
  box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
  border-radius:0;
  background-color: rgba(255,255,255,.8);
}

// Firefox

:root, *{
  scrollbar-color: $red #e4e4e4;
  scrollbar-width: thin;
}

#view-more-less--container {
  padding: 1em 0 0 0;
  text-align: left;

  button {
    background: transparent;
    border: 0;
    outline: none;
    color: $red;
    font-weight: 500;
    font-size: .9em;
    font-style: italic;
    padding: 1.5em 0 1em;
    margin: 0;

    @media screen and(max-width: 1500px) {
      margin: 10px 0;
    }

    &:after {
      content: '+';
      padding-left: 8px;
      transform: scale(1.1);
    }
  }
  .view-more-less--content{
    -webkit-transition: opacity .4s ease, max-height .4s ease;
    transition: opacity .4s ease, max-height .4s ease;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-5px);
  }

  &.display-less {

    .view-more-less--content {
      visibility: visible;
      display: block;
      pointer-events: none;
    }
  }

  &.display-more {

    .view-more-less--content {
      -webkit-transition: opacity .4s ease .3s, max-height .4s ease;
      transition: opacity .4s ease .3s, max-height .4s ease;
      opacity: 0.85;
      pointer-events: auto;
      padding: 0;
      transform: translateY(0px);
    }

    button {
      position: relative;
      &:after {
        content: '-';
      }
    }
  }

}

.b-10, .full {
  clear: both;
}

.landing-header.header-video{
  @media screen and(max-width: 600px) {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    min-height: 0;
    max-height: 0;

    .parallax{
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      position: absolute;

      .container{
        z-index: 4;
      }

      header {
        .parallax-background {
          position: relative;
          opacity: 1;
          -webkit-transition: opacity .4s ease;
          transition: opacity .4s ease;
          z-index: 3;
          &.hide{
            opacity: 0;
            z-index: 1;
          }
        }
        #js-header__video{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;

          iframe{
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}